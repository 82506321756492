import "../style/pages/Afterparty.css";

export default function AAly() {
  return (
    <>
      <h1>Jatkot</h1>
      <h2>
        <b>Utopia</b>
      </h2>
      <p>
        Utopiassa vauhdin jatkoilla hoitaa rohkea ja energinen{" "}
        <b className="artist-name">Benjamin</b>
      </p>
      <h2>
        <b>Forte</b>
      </h2>
      <p>
        Fortessa AATUt kruunaa pop ja R&B artisti{" "}
        <b className="artist-name">Hugo</b>
      </p>
      <h2>
        <b>Marilyn & Vegas</b>
      </h2>
      <p>
        Lygasin jatkoilla AATU-kansaa viihdyttää Turun speksien yhteistyössä
        syntynyt bilebändi <b className="artist-name">Speksivalistus</b>
        <br />
        Bändi esiintyy Marilynin puolella
      </p>
    </>
  );
}
