import { Link } from "react-router-dom";
import "../style/components/Header.css";
import logo_white_notext from "../assets/images/logos/logo_white_notext.png";
import { useRef, useState } from "react";

export default function Header() {
  const [show, setShow] = useState(false);
  const menuBtn = useRef(null);

  const goToTop = () => {
    setShow(!show);
    window.scroll(0, 0);
  };

  return (
    <header className={show ? "show" : ""}>
      <div className="header-container">
        <div className="header-container-text">
          <Link
            to="/"
            onClick={() => {
              if (show) {
                setShow(!show);
              }
            }}
          >
            <img
              src={logo_white_notext}
              alt="Akateeminen Aurajokilaivuritutkinto"
            />
          </Link>
          <h1 className="sm-view">
            AATU <time>16.5.2024</time>
          </h1>
          <h1 className="lg-view">
            Akateeminen Aurajokilaivuritutkinto
            <time>16.5.2024</time>
          </h1>
        </div>
        <input
          name="checkbox"
          type="checkbox"
          className="menu-btn"
          onChange={() => setShow(!show)}
          checked={show}
          ref={menuBtn}
        />
        <div className="hamburger-lines">
          <span className="btn-line"></span>
          <span className="btn-line"></span>
          <span className="btn-line"></span>
        </div>
      </div>
      <nav>
        <ul>
          <Link className="nav-link" to="/" onClick={() => goToTop()}>
            <li>Etusivu</li>
          </Link>

          <Link className="nav-link" to="/liput" onClick={() => goToTop()}>
            <li>Liput</li>
          </Link>
          <Link className="nav-link" to="/ohjelma" onClick={() => goToTop()}>
            <li>Ohjelma</li>
          </Link>
          <Link className="nav-link" to="/tutkinto" onClick={() => goToTop()}>
            <li>Tutkinto</li>
          </Link>
          <Link className="nav-link" to="/jatkot" onClick={() => goToTop()}>
            <li>Jatkot</li>
          </Link>
          <Link
            className="nav-link"
            to="/jaansarkijat"
            onClick={() => goToTop()}
          >
            <li>Jäänsärkijäbileet</li>
          </Link>
          <Link className="nav-link" to="/aaly" onClick={() => goToTop()}>
            <li>AAly</li>
          </Link>
          <Link className="nav-link" to="/ukk" onClick={() => goToTop()}>
            <li>UKK</li>
          </Link>
          <Link
            className="nav-link"
            to="/yhteystiedot"
            onClick={() => goToTop()}
          >
            <li>Yhteystiedot</li>
          </Link>
        </ul>
      </nav>
    </header>
  );
}
