export default function Schedule() {
  return (
    <>
      <h1>OHJELMA</h1>
      <h2>
        LIPPUJEN VAIHTO
        <br />
        <b>klo 12-18</b>
      </h2>
      <h2>
        AATUN AVAUS
        <br />
        <b>klo 14</b>
      </h2>
      <h2>
        TUTKINTOAIKA
        <br />
        <b>klo 14-21</b>
      </h2>
      <h2>
        KARTTOJEN VAIHTO
        <br />
        <b>klo 21-00</b>
      </h2>
      <h2>
        JATKOBILEET
        <br />
        <b>klo 21-04</b>
      </h2>
      <h2>
        SHOWTIME
        <br />
        <b>klo 01</b>
      </h2>
    </>
  );
}
