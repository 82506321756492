export default function IceBreaker() {
  return (
    <>
      <h1>Jäänsärkijäbileet</h1>
      <p>
        AAly järjestää perinteiset Jäänsärkijäbileet torstaina 21.3. klo 21
        alkaen Turun Vegasissa!
      </p>
      <p>
        Jäänsärkijäbileistä voit noutaa oman lippusi Akateemiseen
        Aurajokilaivuritutkintoon ennakkoon alennettuun hintaan sekä tietenkin
        juhlia kesän lähestymistä! Kannattaa olla ajoissa paikalla, sillä
        ennakkolippuja on tarjolla vain rajallinen määrä.
      </p>
      <p>
        Ennakkolippujen hinta on 9 € ja niitä voi ostaa kaksi kpl per henkilö.
      </p>
      <p>Jäänsärkijäbileistä saatavilla myös upea Jäänsärkijämerkki!</p>
      <h2>Ankkapong</h2>
      <p>
        Ennen Jäänsärkijöitä voit osallistua myös AAlyn ja{" "}
        <a href="https://www.beerpongturku.fi/">Beer Pong Turun</a>{" "}
        yhteisturnaukseen AnkkaPongiin! AnkkaPongin pääpalkintona tarjolla mm.
        AATU-lippuja sekä yhteistyökumppanimme tuotteita. AnkkaPongin
        erikoisuutena on, että turnaukseen ilmoittaudutaan yksin ja joukkueet
        arvotaan turnauksen aluksi.
      </p>
      <p>
        AnkkaPongin liput saatavilla{" "}
        <a href="https://kide.app/events/494edcb6-2a1e-45ba-97f9-3d1cda70b7cd">
          Kide.appista
        </a>{" "}
        nyt!
      </p>
    </>
  );
}
