export default function AAly() {
  return (
    <>
      <h1>Akateeminen Aurajokilaivuriyhdistys ry</h1>
      <h2>Mikä</h2>
      <p>
        Akateemisen Aurajokilaivuritutkinnon (tuttavallisemmin AATU) järjestää
        Akateeminen Aurajokilaivuriyhdistys ry (tuttavallisemmin AAly), joka on
        perustettu 22.9.1995 eli muutama kuukausi ensimmäisen Akateemisen
        Aurajokilaivuritutkinnon jälkeen. AAly on voittoa tavoittelematon
        opiskelijayhdistys.
      </p>
      <h2>Ketä</h2>
      <p>
        AAly on tiedekuntarajoista riippumaton Turun yliopiston ylioppilaskunnan
        alayhdistys, jonka toimintaa ohjaa 9 henkinen hallitus. AAlyn jäseneksi
        ovat tervetulleita kaikki opiskelijat, ketkä ovat innokkaita
        järjestämään AATUa.
      </p>
      <h2>Mitä</h2>
      <p>
        AATUn ohella AAly järjestää Jäänsärkijäbileet keväisin, jossa myydään
        AATUn ennakkolippuja edulliseen hintaan. Lisäksi AAly järjestää
        vuosijuhlia satunnaisesti sekä tarjoilee vappuaattona legendaarista
        booliaan varhain aamulla. AAly myös mahdollistaa yhdessä Turun
        yliopiston osakuntien kanssa Osakuntasalin toiminnan.
      </p>
    </>
  );
}
