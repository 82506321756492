import banner from "../assets/images/logos/aatu_banner_edit.jpg";
import kesarauha from "../assets/images/sponsors/kesarauha.png";
import tribunali from "../assets/images/sponsors/tribunali.png";
import rantakerttu from "../assets/images/sponsors/rantakerttu.jpg";
import kilroy from "../assets/images/sponsors/kilroy.png";
import xsec from "../assets/images/sponsors/xsec.png";
import vegas from "../assets/images/bars/vegas.png";
import marilyn from "../assets/images/bars/marilyn.png";
import forte from "../assets/images/bars/forte.png";
import utopia from "../assets/images/bars/utopia.png";
import { ReactComponent as Kide } from "../assets/images/sponsors/kide.svg";

export default function Home() {
  return (
    <>
      <img className="banner" src={banner} alt="Panorama of The Aura River" />
      <p>
        Akateeminen Aurajokilaivuritutkinto AATU on perinteikäs approtapahtuma,
        joka järjestetään Suomen Turussa aina toukokuussa. Tänä vuonna
        AATUillaan jo 29. kertaa torstaina 16.5. Aurajoen rannan kesäisissä
        maisemissa.
      </p>
      <h1>Liput</h1>
      <p>
        Liput saatavilla{" "}
        <a href="https://kide.app/events/0110fafc-ec1a-4b70-8853-42e761e3d385">
          Kide.appista
        </a>{" "}
        nyt!
      </p>
      <div className="afterparty-container">
        <h1>Jatkot</h1>
        <p>Jatkoja vietetään tänä vuonna yhteensä neljässä eri yökerhossa</p>
        <div className="img-row">
          <div className="img-container">
            <a href="https://utopiaclub.fi/">
              <img src={utopia} alt="Utopia Club" />
            </a>
          </div>
          <div className="img-container">
            <a href="https://nightclub.fi/">
              <img src={forte} alt="Forte" />
            </a>
          </div>
          <div className="img-container">
            <a href="https://clubmarilyn.fi/">
              <img src={marilyn} alt="Club Marilyn" />
            </a>
          </div>
          <div className="img-container">
            <a href="https://clubmarilyn.fi/vegas/">
              <img src={vegas} alt="Night Club Vegas" />
            </a>
          </div>
        </div>
      </div>
      <section>
        <h1>Tapahtuman mahdollistaa</h1>
        <div className="sponsor-container">
          <div
            style={{ display: "flex", alignItems: "center" }}
            className="sponsor-img-container"
          >
            <a href="https://www.kesarauha.fi/">
              <img src={kesarauha} alt="Kesärauha" />
            </a>
          </div>
          <div className="sponsor-img-container">
            <a href="https://viatribunali.fi/">
              <img
                style={{ width: "40%" }}
                src={tribunali}
                alt="Via Tribunali"
              />
            </a>
          </div>
          <div className="sponsor-img-container">
            <a href="https://www.kilroy.fi/">
              <img src={kilroy} alt="Kilroy" />
            </a>
          </div>
          <div className="sponsor-img-container">
            <a href="https://xsec.fi/">
              <img src={xsec} alt="X-Sec" />
            </a>
          </div>
          <div className="sponsor-img-container">
            <a href="https://www.rantakerttu.fi/">
              <img
                style={{ width: "40%" }}
                src={rantakerttu}
                alt="Rantakerttu"
              />
            </a>
          </div>
          <div className="sponsor-img-container" style={{ marginTop: "4rem" }}>
            <a href="https://kide.app/">
              <Kide />
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
