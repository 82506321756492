export default function Tickets() {
  return (
    <>
      <h1>LIPUT</h1>
      <h2>NETTILIPUT</h2>
      <p>
        AATU-liput myynnissä 16.5. klo 18.00 asti{" "}
        <a href="https://kide.app/events/0110fafc-ec1a-4b70-8853-42e761e3d385">
          Kide.appissa
        </a>
        !
      </p>
      <p>
        Jatkolliset liput 15 €
        <br />
        Jatkottommat liput 9 €
      </p>
      <p>
        Lipun oston yhteydessä voit myös ostaa itsellesi Tuplatutkintolipun,
        jolla saat toisen tutkintokartan suoritettavaksi edullisempaan hintaan
        <br />
        Tuplatutkintolippu maksaa 8 €
      </p>
      <h2>RYHMÄTILAUKSET</h2>
      <p>
        Ryhmätilaukset vuoden 2024 AATUn osalta ovat sulkeutuneet. Lisätäksesi
        järjestösi ensi vuoden ryhmätilauksien infolistalle ota yhteyttä
        <br />
        <a href="mailto:liput@aatu.fi">liput@aatu.fi</a>
      </p>
    </>
  );
}
